.App,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  height: 100vh;
  transition: background-color 0.7s ease-in-out, color 0.7s ease-in-out;
}

html,
body {
  overflow-x: hidden;
  margin: 0;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #5f6371;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #8d94aa;
}

[role="columnheader"] {
  background-color: #5f6371;
  min-width: 40px;
  z-index: 1;
  color: #ffffff;
  font-weight: 600 !important;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

[role="columnheader"]:hover {
  background-color: #5f6371 !important;
}

[role="button"]:hover {
  background-color: #5f6371;
}

[role="rowgroup"] {
  border-radius: 30px 30px 0px 0px;
  margin-right: -7px;
}

#table-header {
  background-color: #5f6371;
  z-index: 1;
  padding-right: 7px;
}

.scaledIcon:hover {
  transform: scale(1.2);
}
.scaledIcon {
  cursor: pointer;
  transition: all 0.2s;
  transform-origin: 50% 50%;
}

.ReactCollapse--collapse {
  float: right;
  transition: height 300ms;
}

.optionListContainer {
  z-index: 1001 !important;
}

.chip {
  background: #5f6371 !important;
  margin-bottom: 0 !important;
  padding: 3px !important;
  font-size: 13px !important;
  line-height: 17px !important;
}

.searchWrapper {
  background-color: transparent !important;
  min-height: 36px !important;
}

.multiSelectContainer li:hover {
  background: #5b5ec765 !important;
  color: white !important;
  cursor: pointer;
}

.optionContainer {
  background-color: #5f6371 !important;
}

.optionListContainer {
  background-color: #5f6371 !important;
}

.groupHeading {
  color: white !important;
}

.groupChildEle {
  color: white !important;
}

input[type="checkbox"] {
  accent-color: #5f6371 !important;
}

.ant-pagination-item-active {
  background-color: transparent !important;
  border-color: #ffffff !important;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}

.ant-pagination-item a {
  color: #ffffff !important;
}

.ant-pagination button {
  color: #ffffff !important;
}

#theme-toggle {
  visibility: hidden;
}

#theme-toggle + label {
  content: "";
  display: inline-block;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.6s ease-in-out;
  margin-top: 25px;
}

#theme-toggle:not(:checked) + label {
  background-color: gold;
}

#theme-toggle:checked + label {
  background-color: transparent;
  box-shadow: inset -9px -8px 1px 1px #fff;
}

.ant-select-selector {
  background-color: transparent !important;
  color: white !important;
}

.ant-select-dropdown {
  background-color: #5f6371 !important;
}
